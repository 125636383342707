class FunctionCallLimiter {
  constructor(maxCalls, timeFrame) {
    this.maxCalls = maxCalls;
    this.timeFrame = timeFrame;
    this.calls = [];
  }

  call(func) {
    const now = Date.now();

    this.calls = this.calls.filter((callTime) => now - callTime < this.timeFrame);

    if (this.calls.length < this.maxCalls) {
      this.calls.push(now);
      func();
    } else {
      console.log('Function call aborted: too many calls within time frame');
    }
  }
}

export default FunctionCallLimiter;
