/* eslint-disable react/no-unknown-property */
/* eslint-disable import/no-cycle */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-return-await */
import React, {
  memo, useEffect, useMemo, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import HtmlParser from 'react-html-parser';
import { Button } from 'antd';
import { useRouter } from 'next/router';
import useAnalytics from '../../../hooks/useAnalytics';
import style from './PromoHeaderBanner.module.scss';
import { closePromoBanner, posthogState } from '../../../store/reducers/posthogReducer';
import {
  currentActiveNotification, notificationExludePaths, notificationState, queueNotification, setInactiveNotification, unpaidType, unpaidTypeArray,
} from '../../../store/reducers/notificationReducer';
import classnames from '../../../utils/classnames';
import Helpers from '../../../utils/Helpers';
import { paymentUrl } from '../../../config/getdomain';
import useAuth from '../../../hooks/useAuth';
import useLoading from '../../../hooks/useLoading';
// import SurveyCreator from '../survey_creator';
// import config from '../survey_creator/config';
import { authState } from '../../../store/reducers/authReducer';
import PaymentServices from '../../../services/PaymentServices';
import currFormat from '../../../utils/currencyFormatter';
import { appState, setIframePopup } from '../../../store/reducers/appReducer';
import Segmentations from '../../../utils/Segmentations';
import Versioning from '../../../utils/Versioning';

function PromoHeaderBanner() {
  const { verifyEmailSent, verifyEmailPopup } = useSelector(notificationState);
  const { userData } = useSelector(authState);
  const { handleVerify } = useAuth();

  return verifyEmailPopup && (
    <div className={style.container} suppressHydrationWarning>
      <section className={style.title} suppressHydrationWarning>
        <span suppressHydrationWarning>{verifyEmailSent ? 'Please check your email' : HtmlParser(`Please verify your email (<b>${userData?.Email}</b>)`)}</span>
      </section>
      <section className={style.button} suppressHydrationWarning>
        {!verifyEmailSent && (
          <button
            type="button"
            onClick={handleVerify}
          >
            Re-send Verification Email
          </button>
        )}
      </section>
    </div>
  );
}

export function PromoHeaderBannerWithPosthog() {
  const dispatch = useDispatch();
  const { promoBanners } = useSelector(posthogState);
  const { listAppByKeyword, currency } = useSelector(appState);
  const { userMeta, userPlan, user, userData } = useSelector(authState);

  useEffect(() => {
    promoBanners?.forEach((promoBanner) => {
      dispatch(queueNotification({
        type: 'promo_banner',
        key: `promo_banner_${promoBanner?.id}`,
        payload: promoBanner,
      }));
    });

    if (user?.uid) {
      Object.keys(listAppByKeyword || {})
        .sort((a, b) => listAppByKeyword[a]?.value?.conditions?.segment_membership ? -1 : 1)
        .forEach((key) => {
          if (Segmentations.inCriteriaConfigCheck({
            key,
            targetKey: 'promo_banner',
            configs: listAppByKeyword,
            userMeta,
            userPlan,
            currency,
            user,
            userData
          }) && !Versioning.ONE_TIME_CREATION()) {
            dispatch(queueNotification({
              type: 'promo_banner',
              key: `promo_banner_${listAppByKeyword[key]?.value?.id}`,
              payload: listAppByKeyword[key]?.value,
            }));
          }
        });
    }
  }, [dispatch, promoBanners, listAppByKeyword, user, userMeta, userPlan, currency]);

  const _currentActiveNotification = useSelector(currentActiveNotification);

  const promoBanner = useMemo(() => {
    if (_currentActiveNotification?.type === 'promo_banner') {
      return _currentActiveNotification.payload;
    }
    return null;
  }, [_currentActiveNotification]);

  const { logEvent } = useAnalytics();

  const handleClose = ({ withCooldown = false }) => {
    dispatch(closePromoBanner(promoBanner.id));
    dispatch(setInactiveNotification({ withCooldown }));
  };

  const [handleCsShow, setHandleCsShow] = useState(false);
  const { pathname } = useRouter();
  useEffect(() => {
    setHandleCsShow(!!promoBanner && !notificationExludePaths.some((route) => pathname.includes(route)) && promoBanner?.end_date ? new Date(promoBanner?.end_date).getTime() > Date.now() : true && promoBanner?.conditions?.paid !== null ? (!promoBanner?.conditions?.paid ? !userPlan?.expire_date : userPlan?.expire_date) : true && !Versioning.ONE_TIME_CREATION());
  }, [promoBanner, pathname]);

  const [hasLogEvents, setHasLogEvents] = useState([]);
  const logDisplay = async () => logEvent(Helpers.promoEventLog({
    promoType: 'banner',
    promoAction: 'displayed',
    featureFlag: promoBanner.featureFlag,
    key: promoBanner.id,
    appConfigMode: promoBanner?.conditions,
  }));

  useEffect(() => {
    if (!!promoBanner && handleCsShow) {
      if (!hasLogEvents.includes(promoBanner.id)) {
        logDisplay();
        setHasLogEvents([...hasLogEvents, promoBanner.id]);
      }
    }
  }, [promoBanner, handleCsShow]);

  return (handleCsShow && promoBanner
    && (
      <div
        className={style.container}
        style={{ background: promoBanner?.background_style }}
      >
        <section className={style.title} style={{ color: promoBanner?.foreground_color }}>
          <h4>{promoBanner.title}</h4>
          <p style={{ color: promoBanner?.foreground_color }}>{HtmlParser(promoBanner.subtitle)}</p>
        </section>
        <section className={style.button}>
          <button
            type="button"
            onClick={async () => {
              const logParams = Helpers.promoEventLog({
                promoType: 'banner',
                promoAction: 'cta_clicked',
                featureFlag: promoBanner.featureFlag,
                key: promoBanner.id,
                appConfigMode: promoBanner?.conditions,
              });
              await logEvent(logParams);
              window.open(promoBanner.button_url, promoBanner.button_target);
              handleClose({ withCooldown: false });
            }}
            style={{
              color: promoBanner?.foreground_color,
              borderColor: promoBanner?.foreground_color,
            }}
            onMouseEnter={(e) => {
              e.target.style.backgroundColor = promoBanner?.foreground_color;
              e.target.style.color = promoBanner?.hover_color || '#000';
            }}
            onMouseLeave={(e) => {
              e.target.style.color = promoBanner?.foreground_color;
              e.target.style.backgroundColor = 'transparent';
            }}
          >
            {promoBanner.button_label}
          </button>
          <button
            type="button"
            className={style.dismiss}
            style={{
              color: promoBanner?.foreground_color,
            }}
            onMouseEnter={(e) => {
              e.target.style.color = promoBanner?.hover_color || '#000';
            }}
            onMouseLeave={(e) => {
              e.target.style.color = promoBanner?.foreground_color;
            }}
            onClick={async () => {
              const logParams = Helpers.promoEventLog({
                promoType: 'banner',
                promoAction: 'dismissed',
                featureFlag: promoBanner.featureFlag,
                key: promoBanner.id,
                appConfigMode: promoBanner?.conditions,
              });
              await logEvent(logParams);
              handleClose({ withCooldown: true });
            }}
          >
            X
          </button>
        </section>
      </div>
    )
  );
}

export function UnpaidInvoiceHeaderBanner() {
  const dispatch = useDispatch();

  const _currentActiveNotification = useSelector(currentActiveNotification);
  const { userData } = useSelector(authState);
  const { currency: { code } } = useSelector(appState);
  const { iframePopup: { open: iframePopupOpen } } = useSelector(appState);

  const { user } = useAuth();

  const unpaidInvoiceHeaderBanner = useMemo(() => {
    if (_currentActiveNotification?.type === 'unpaid_invoice' && _currentActiveNotification?.payload?.type === 'banner') {
      if (unpaidTypeArray.includes(_currentActiveNotification.payload?.pricing_plan?.plans_type) && _currentActiveNotification?.uid === user?.uid) {
        return _currentActiveNotification.payload;
      }
      dispatch(setInactiveNotification({ withCooldown: false }));
    }
    return null;
  }, [_currentActiveNotification?.payload, _currentActiveNotification?.type, _currentActiveNotification?.uid, dispatch, user?.uid]);

  const eventParams = useMemo(() => ({
    order_id: unpaidInvoiceHeaderBanner?.order_id,
    product: unpaidInvoiceHeaderBanner?.pricing_plan?.name,
    product_type: unpaidInvoiceHeaderBanner?.pricing_plan?.plans_type,
    payment_type: unpaidInvoiceHeaderBanner?.payments_method === 'midtrans' ? 'Midtrans' : 'Stripe',
  }), [unpaidInvoiceHeaderBanner]);

  const { logEvent } = useAnalytics();

  const handleClose = async (hasCheckOut = false, invoice = '') => {
    if (invoice) {
      dispatch(setIframePopup({ open: true, url: `${paymentUrl()}/status/${invoice}?currency=${code}&token=${userData?.accessToken}` }));
    }

    if (!hasCheckOut) {
      await logEvent({
        eventName: 'unpaid_invoice_banner_dismissed',
        eventParams,
        posthog: true,
      });
      dispatch(setInactiveNotification({ withCooldown: true }));
    } else {
      dispatch(setInactiveNotification({ withCooldown: false }));
    }
  };

  const handleCheckOut = async () => {
    await logEvent({
      eventName: 'unpaid_invoice_banner_cta_clicked',
      eventParams,
      posthog: true,
    });

    handleClose(true, unpaidInvoiceHeaderBanner?.order_id);
  };

  const [handleCsShow, setHandleCsShow] = useState(false);

  const { pathname } = useRouter();
  useEffect(() => {
    console.log({ iframePopupOpen });
    setHandleCsShow(!!unpaidInvoiceHeaderBanner && !notificationExludePaths.some((route) => pathname.includes(route)) && !iframePopupOpen);
  }, [unpaidInvoiceHeaderBanner, pathname, iframePopupOpen]);

  const [hasLogEvents, setHasLogEvents] = useState([]);

  const logDisplay = async () => logEvent({
    eventName: 'unpaid_invoice_banner_displayed',
    eventParams,
    posthog: true,
  });

  useEffect(() => {
    if (unpaidInvoiceHeaderBanner) {
      if (!hasLogEvents.includes(unpaidInvoiceHeaderBanner.order_id)) {
        logDisplay();
        setHasLogEvents([...hasLogEvents, unpaidInvoiceHeaderBanner.order_id]);
      }
    }
  }, [unpaidInvoiceHeaderBanner]);

  // const [showSurvey, setShowSurvey] = useState(false);

  // const handleSurvey = (data) => {
  //   console.log({ xxx___: data });
  //   dispatch(setInactiveNotification({ withCooldown: false }));
  //   setShowSurvey(false);
  //   // TODO : Send to backend, cancel invoice
  // };

  const planCurrency = useMemo(() => (
    unpaidInvoiceHeaderBanner?.pricing_plan?.currency
  ), [unpaidInvoiceHeaderBanner]);

  const content = useMemo(
    () => {
      const image = unpaidInvoiceHeaderBanner?.pricing_plan?.thumbnail;
      const imageBgColor = unpaidInvoiceHeaderBanner?.pricing_plan?.backgroundColor;
      let title = '';
      let subtitle = '';
      let type = '';

      switch (unpaidInvoiceHeaderBanner?.pricing_plan?.plans_type) {
        case unpaidType.PLAN:
          title = 'Your plan is waiting to check out 👀';
          subtitle = 'Check out your plan and enjoy the exclusive features!';
          type = `${Helpers.ucFirst(unpaidInvoiceHeaderBanner?.pricing_code)} Plan`;
          break;
        case unpaidType.COIN:
          title = 'Your coin is waiting to check out 👀';
          subtitle = 'Check out your coin for more access to mode bundles';
          type = unpaidInvoiceHeaderBanner?.pricing_plan?.name;
          break;
        case unpaidType.MARKER:
          title = 'Your marker is waiting to check out 👀';
          subtitle = 'Check out your marker and enjoy marking your favorite creations!';
          type = `${unpaidInvoiceHeaderBanner?.quantity} Marker`;
          break;
        case unpaidType.LIMITED_ADDONS:
          title = 'Your pending purchase is waiting to checkout 👀';
          subtitle = 'Check out your pending purchase for more extra project';
          type = `${unpaidInvoiceHeaderBanner?.pricing_plan?.name}`;
          break;
        default:
          break;
      }

      return {
        image,
        imageBgColor,
        title,
        subtitle,
        type,
      };
    },
    [unpaidInvoiceHeaderBanner?.pricing_plan?.plans_type],
  );

  return (handleCsShow && unpaidInvoiceHeaderBanner
    && (
      <>
        <div className={style.overlay} />
        <div className={classnames(style.container, style.unpaid_invoice)}>
          <div
            className={style.plan}
          >
            <div
              className={classnames(style.plan_img)}
              style={{
                background: `#${content.imageBgColor}`.replace('##', '#'),
              }}
            >
              <img alt="plan" src={content.image} />
            </div>
            <span>
              {content.type}
            </span>
          </div>
          <section className={style.intro}>
            <h4>{content.title}</h4>
            <p>{content.subtitle}</p>
          </section>
          <section className={style.actions}>
            {unpaidInvoiceHeaderBanner?.gross_amount
              && (
                <Button
                  onClick={handleCheckOut}
                  className={style.btn_checkout}
                >
                  {`Purchase ${currFormat(unpaidInvoiceHeaderBanner?.gross_amount, planCurrency)}`}
                </Button>
                //   {/* <Button
                //   onClick={() => {
                //     setShowSurvey(true)
                //   }}
                //   className={style.btn_cancel}
                // >
                //   Cancel Purchase
                // </Button> */}
              )}
            <Button
              className={style.btn_dismiss}
              onClick={() => handleClose(false)}
            >
              Dismiss
            </Button>

          </section>
        </div>
        {/* <Modal
        className="without-padding"
        footer={null}
        onCancel={() => setShowSurvey(false)}
        title="Cancel Purchase"
        open={showSurvey}
        onHide={() => setShowSurvey(false)}
        centered
      >
        <SurveyCreator
          onOk={handleSurvey}
          surveyJsConfig={config({ email: user?.email })}
        />
      </Modal> */}
      </>
    )
  );
}

export function RenewalInvoiceHeaderBanner() {
  const dispatch = useDispatch();
  const { loadings, startLoadingWithKey, stopLoadingWithKey } = useLoading();

  const _currentActiveNotification = useSelector(currentActiveNotification);
  const { userData } = useSelector(authState);
  const { currency: { code } } = useSelector(appState);

  const { user } = useAuth();

  const renewalInvoiceHeaderBanner = useMemo(() => {
    if (_currentActiveNotification?.type === 'renewal_invoice' && _currentActiveNotification?.payload?.type === 'banner') {
      if (_currentActiveNotification.payload?.plans_type === 'Personal' && _currentActiveNotification?.uid === user?.uid) {
        return _currentActiveNotification.payload;
      }
      dispatch(setInactiveNotification({ withCooldown: false }));
    }
    return null;
  }, [_currentActiveNotification?.payload, _currentActiveNotification?.type, _currentActiveNotification?.uid, dispatch, user?.uid]);

  const eventParams = useMemo(() => ({
    order_id: renewalInvoiceHeaderBanner?.invoice,
    product: renewalInvoiceHeaderBanner?.plans_name,
    product_type: renewalInvoiceHeaderBanner?.plans_type,
    payment_type: renewalInvoiceHeaderBanner?.payment_method === 'midtrans' ? 'Midtrans' : 'Stripe',
  }), [renewalInvoiceHeaderBanner]);

  const { logEvent } = useAnalytics();

  const handleClose = async (hasCheckOut = false, invoice = '') => {
    if (invoice) {
      const params = new URLSearchParams({
        currency: code,
        renewal: true,
        token: userData?.accessToken,
      });
      dispatch(setIframePopup({ open: true, url: `${paymentUrl()}/status/${invoice}?${params.toString()}` }));
    }

    if (!hasCheckOut) {
      await logEvent({
        eventName: 'renewal_invoice_banner_dismissed',
        eventParams,
        posthog: true,
      });
      dispatch(setInactiveNotification({ withCooldown: true }));
    } else {
      dispatch(setInactiveNotification({ withCooldown: false }));
    }
  };

  const handleCheckOut = async () => {
    try {
      startLoadingWithKey('renewalInvoice');

      await logEvent({
        eventName: 'renewal_invoice_banner_cta_clicked',
        eventParams,
        posthog: true,
      });

      const renewalInvoice = await PaymentServices.renewalPayment({
        invoice: renewalInvoiceHeaderBanner?.invoice,
        method: renewalInvoiceHeaderBanner?.payment_method,
      });
      setTimeout(() => {
        stopLoadingWithKey('renewalInvoice');
        handleClose(true, renewalInvoice?.invoice);
      }, 3000);
    } catch (error) {
      console.log(error);
      stopLoadingWithKey('renewalInvoice');
    }
  };

  const [handleCsShow, setHandleCsShow] = useState(false);

  const { pathname } = useRouter();
  useEffect(() => {
    setHandleCsShow(!!renewalInvoiceHeaderBanner && !notificationExludePaths.some((route) => pathname.includes(route)));
  }, [renewalInvoiceHeaderBanner, pathname]);

  const [hasLogEvents, setHasLogEvents] = useState([]);

  const logDisplay = async () => logEvent({
    eventName: 'renewal_invoice_banner_displayed',
    eventParams,
    posthog: true,
  });

  useEffect(() => {
    if (renewalInvoiceHeaderBanner) {
      if (!hasLogEvents.includes(renewalInvoiceHeaderBanner.invoice)) {
        logDisplay();
        setHasLogEvents([...hasLogEvents, renewalInvoiceHeaderBanner.invoice]);
      }
    }
  }, [renewalInvoiceHeaderBanner]);

  return (handleCsShow && renewalInvoiceHeaderBanner
    && (
      <>
        <div className={style.overlay} />
        <div className={classnames(style.container, style.renewal)}>
          <div
            className={style.plan}
          >
            <div className={classnames(style.plan_img, style.plan_img_renewals, style[renewalInvoiceHeaderBanner.pricing_code.toLowerCase()])}>
              {/* <img alt="plan" src="/assets/icons/plans/renewal_alert.svg" /> */}
              <img alt="plan" src={`/assets/icons/plans/${renewalInvoiceHeaderBanner.pricing_code.toLowerCase()}.svg`} />
            </div>
            <span>
              {Helpers.ucFirst(renewalInvoiceHeaderBanner.pricing_code)}
              {' '}
              Plan
            </span>
          </div>
          <section className={style.intro}>
            <h4>Times Up! Renew Now to keep the Premium Benefits</h4>
            <p>Ongoing benefits and extended access to premium features!</p>
          </section>
          <section className={style.actions}>
            {renewalInvoiceHeaderBanner?.plans_price && (renewalInvoiceHeaderBanner?.quantity || 1)
              && (
                <Button
                  onClick={handleCheckOut}
                  className={style.btn_checkout}
                  loading={loadings.renewalInvoice}
                >
                  Renew for
                  {' '}
                  {currFormat(renewalInvoiceHeaderBanner.plans_price * (renewalInvoiceHeaderBanner?.quantity || 1), renewalInvoiceHeaderBanner.currency)}
                </Button>
              )}
            <Button
              className={style.btn_dismiss}
              onClick={() => handleClose(false)}
            >
              Dismiss
            </Button>

          </section>
        </div>
      </>
    )
  );
}

export default memo(PromoHeaderBanner);
