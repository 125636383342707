/* eslint-disable react-hooks/exhaustive-deps */
import { useRouter } from "next/router";
import { Fragment, useState, useEffect, useRef, useCallback, memo } from "react";
import { IconSearch } from "../../icons/Icons";
import styles from "./SearchbarExplore.module.scss";
import qs from 'querystring'
import instance from "../../../config/axios";
import { useDispatch } from "react-redux";
import { setExploreSearchPosition } from "../../../store/reducers/helperReducer";
import classnames from "../../../utils/classnames";

const SearchBarExplore = ({ idElement, withSuggest, marketplace, from }) => {
  const router = useRouter();
  const [text, setText] = useState('');
  const [dropdown, setDropdown] = useState(null)
  let searchChild = 0
  const [categorySelected, setCategorySelected] = useState({ projects: '', templates: '' })
  const [suggestion, setSuggestion] = useState(['Animal', 'Character', 'Vehicle', 'Dinosaur'])
  const searchCategory = [
    { name: 'AR Templates', value: "templates" },
    { name: '3D Marketplace', value: "all" },
  ]
  const marketplaceCategory = [
    { name: '3D Objects', value: "item3D" },
    { name: '3D Bundles', value: "bundle" },
    { name: '3D Marketplace', value: "all" },
  ]

  const option = marketplace ? marketplaceCategory : searchCategory
  const dispatch = useDispatch();

  const searchBarRef = useRef(null)
  const setCurrentPosition = () => {
    if (!searchBarRef.current && idElement !== 'explore') return
    const positions = {
      topFromBody: searchBarRef.current?.getBoundingClientRect().top - document.body.getBoundingClientRect().top,
    }
    if (positions.topFromBody > 315) positions.topFromBody = 315
    dispatch(setExploreSearchPosition(positions))
  }

  useEffect(() => {
    if (idElement === 'explore') {
      window.addEventListener('onload', setCurrentPosition)
      window.addEventListener('resize', setCurrentPosition)
      setTimeout(() => {
        setCurrentPosition()
      }, 1000)
    }
  }, [idElement, router.pathname])

  const handleSearch = (type, noKeyword) => {
    const params = {}
    params.type = type ?? 'templates'
    if (text && !noKeyword) params.q = text

    if (type && type !== 'templates') {
      document.body.style = 'overflow: auto'
      router.replace(`/marketplace/search?${qs.stringify(params)}`);
    } else {
      document.body.style = 'overflow: auto'
      if (categorySelected[router.query.type]) params.category = categorySelected[router.query.type]
      router.replace({
        pathname: '/search',
        query: {
          ...router.query,
          ...params,
          q: noKeyword ? '' : text,
          page: 1
        }
      }, undefined, { shallow: true })
    }


    setDropdown(false)
    window.parent.postMessage({ id: 'close-search' }, '*')
  }

  const handleDropdown = () => {
    if (text && router.pathname !== '/search') setDropdown(true)
    else setDropdown(false)
  }

  const fetchSuggestion = useCallback(async () => {
    let { data, status } = await instance.get(`/api/settings/list-app/?key=search_suggestion_studio`)

    if (status === 200) {
      setSuggestion(data[0].value.list)
    }
  })
  

  useEffect(() => {
    if (idElement) {
      const searchbar = document.getElementById(`searchbar${idElement}`);
      const container = document.getElementById(`container${idElement}`);
      const elList = document.querySelectorAll(`ul#dropdown${idElement} li`);
      const maxChild = elList.length - 1;

      const searchNavigation = (calc) => {
        Array.from(elList).forEach((v) => v.classList.remove(styles.selected));
        searchChild = calc;
        const el = document.getElementById(
          `dropdown${idElement + searchChild}`
        );
        if (el) el.classList.add(styles.selected);
      };

      document.addEventListener("click", function (evt) {
        if (evt.target.id !== `searchbar${idElement}`) {
          setDropdown(false);
          container?.classList?.remove(styles.focus)
        }
        else {
          handleDropdown()
          container?.classList?.add(styles.focus)
        };
        searchNavigation(0);
      });

      searchbar.addEventListener("keyup", function (ev) {
        if (ev.key === "ArrowDown") {
          searchNavigation(searchChild === maxChild ? 0 : searchChild + 1);
        }
        if (ev.key === "ArrowUp") {
          searchNavigation(searchChild === 0 ? maxChild : searchChild - 1);
        }
        if (ev.key !== "ArrowDown" && ev.key !== "ArrowUp" && ev.key !== 'Enter') searchNavigation(0);
      });

      window.addEventListener('message', function (evt) {
        if (evt.data.id === 'focus-explore-searchbar') {
          searchbar.focus()
          searchbar.innerText = 'test'
          container.classList.add(styles.focus)
        }
      })
    }
  }, [text])

  useEffect(() => {
    if (router.isReady) {
      const { type, category, q } = router.query

      setText(q ?? '')

      if (router.query.hasOwnProperty('category')) {
        const categoryObj = {}
        categoryObj[type] = category
        setCategorySelected({ ...categorySelected, ...categoryObj })
      }
    }
  }, [router])

  useEffect(() => {
    fetchSuggestion()
  }, [])

  return (
    <Fragment>
      <div
        ref={searchBarRef}
        id={`container${idElement}`}
        className={classnames(`${styles["searchbar_container"]} ${router.pathname === "/search" ? styles.search : ""
          }`, router.pathname === "/explore" ? styles.explore : "")}
        onClick={() => {
          if (from === "explore-page" && router.pathname.includes("explore")) {
            // document.body.style = 'overflow: hidden'
            // window.scroll({ top: 0, behavior: "smooth" })
            // window.postMessage({ id: "open-search" }, "*")
            router.push("/search")
          }
        }}
      >
        <div className={styles.inputWrapper}>
          <IconSearch className={styles["ic_search"]} />
          <input
            id={`searchbar${idElement}`}
            placeholder="Enter your keywords"
            readOnly={idElement === 'explore' && router.pathname === '/explore' ? true : false}
            onChange={(e) => {
              const value = e.target.value;
              setText(value);
            }}
            onFocus={() => {
              handleDropdown();
            }}
            autoFocus={true}
            onKeyUp={(ev) => {
              if (ev.key === "Enter" && text.length >= 3) {
                ev.preventDefault();
                const el = document.querySelector(`li.${styles.selected}`);
                if (dropdown) {
                  let type = "";
                  const getId = parseInt(el.id.substring(el.id.length - 1));
                  if (marketplace) type = getId === 0 ? "item3D" : (getId === 1) ? "bundle" : "all";
                  else type = getId === 0 ? "templates" : "all";
                  handleSearch(type);
                } else handleSearch();
              } else if (!text && router.pathname === "/search")
                handleSearch(null, true);
              else handleDropdown();
            }}
            value={text}
            autoComplete="off"
          />
          {text ? (
            <div
              className={styles["button_clear"]}
              onClick={() => {
                setText("");
                if (router.pathname === "/search") handleSearch(null, true);
              }}
            >
              <p>x</p>
            </div>
          ) : null}
        </div>
        <ul
          className={styles.dropdown}
          style={{
            display: dropdown ? "block" : "none",
            cursor: dropdown ? "pointer" : "default",
          }}
          id={`dropdown${idElement}`}
        >
          {option.map((v, idx) => (
            <li
              key={v.name + idx}
              id={`dropdown${idElement + idx}`}
              onClick={() => {
                if (text.length >= 3) handleSearch(v.value);
              }}
            >
              search <b>{text}</b> in <b>{v.name}</b>
            </li>
          ))}
        </ul>
      </div>
      {withSuggest && !text ? (
        <div className={styles.suggestWrapper}>
          <p>Ideas for you</p>
          <div>
            {suggestion.map((v, idx) => (
              <button
                key={v + idx}
                className={styles.suggestButton}
                onClick={() => {
                  // window.postMessage({ id: "focus-explore-searchbar" }, "*");
                  setText(v);
                }}
              >
                {v}
              </button>
            ))}
          </div>
        </div>
      ) : null}
    </Fragment>
  );
};

export default memo(SearchBarExplore);
