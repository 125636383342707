import { useDispatch, useSelector } from "react-redux";
import { helperState, setLastFetch } from "../store/reducers/helperReducer";

const useFethTime = () => {
    const dispatch = useDispatch();

    const setLastFetchTime = (key) => {
        dispatch(setLastFetch(key));
    }

    const { lastFetch } = useSelector(helperState);

    const mustFetch = (key, { hours = 0, minutes = 0, seconds = 0 } = {}) => {
        const lastFetchTime = lastFetch[key] || 0;
        if (lastFetchTime === 0) {
            return true;
        }
        const now = Date.now();
        const lastFetchDate = new Date(parseInt(lastFetchTime));
        const lastFetchDatePlus = new Date(lastFetchDate.getTime() + hours * 60 * 60 * 1000 + minutes * 60 * 1000 + seconds * 1000);
        return lastFetchDatePlus < now;
    }

    return {
        setLastFetchTime,
        mustFetch
    }
}

export default useFethTime;
