const icon = {
  scan: {
    normal: '/assets/icons/Normal-Icon-Explore-1.png',
    active: '/assets/icons/Active-Icon-Explore-1.png',
    hover: '/assets/icons/Hover-Icon-Explore-1.png',
    white: '/assets/icons/w-Normal-Icon-Explore-1.png',
  },
  explore: {
    normal: '/assets/icons/Normal-Icon-Explore.png',
    active: '/assets/icons/Active-Icon-Explore.png',
    hover: '/assets/icons/Hover-Icon-Explore.png',
    white: '/assets/icons/w-Normal-Icon-Explore.png',
  },
  home: {
    normal: '/assets/icons/Normal-Icon-Home.png',
    active: '/assets/icons/Active-Icon-Home.png',
    hover: '/assets/icons/Hover-Icon-Home.png',
    white: '/assets/icons/w-Normal-Icon-Home.png',
  },
  marketplace: {
    normal: '/assets/icons/Normal-Icon-Marketplace.png',
    active: '/assets/icons/Active-Icon-Marketplace.png',
    hover: '/assets/icons/Hover-Icon-Marketplace.png',
    white: '/assets/icons/w-Normal-Icon-Marketplace.png',
  },
  templates: {
    normal: '/assets/icons/Normal-Icon-Templates.png',
    active: '/assets/icons/Active-Icon-Templates.png',
    hover: '/assets/icons/Hover-Icon-Templates.png',
    white: '/assets/icons/w-Normal-Icon-Templates.png',
  },
  projects: {
    normal: '/assets/icons/Normal-Icon-Projects.png',
    active: '/assets/icons/Active-Icon-Projects.png',
    hover: '/assets/icons/Hover-Icon-Projects.png',
    white: '/assets/icons/w-Normal-Icon-Projects.png',
  },
  profile: {
    normal: '/assets/icons/Normal-Icon-User.png',
    active: '/assets/icons/Active-Icon-User.png',
    hover: '/assets/icons/Hover-Icon-User.png',
    white: '/assets/icons/w-Normal-Icon-User.png',
  },
  creator: {
    normal: '/assets/icons/Normal-Icon-Creator.png',
    active: '/assets/icons/Active-Icon-Creator.png',
    hover: '/assets/icons/Hover-Icon-Creator.png',
    white: '/assets/icons/w-Normal-Icon-Creator.png',
  },
};

export default icon;
