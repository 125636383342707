import React from 'react';
import { BiSolidCoupon } from 'react-icons/bi';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import { getAuth, getIdToken } from 'firebase/auth';
import { IconStar } from '../../icons/Icons';
import styles from './UpgradeCard.module.scss';
import profileStyles from '../../../styles/Profile.module.scss';
import classnames from '../../../utils/classnames';
import { appState, setIframePopup } from '../../../store/reducers/appReducer';
import { paymentUrl } from '../../../config/getdomain';
import { currentDistinctId } from '../../../hooks/useAnalytics';

function UpgradePlanCard(props) {
  const { onUpgrade, type, activePlan } = props;

  const { push } = useRouter();
  const dispatch = useDispatch();
  const auth = getAuth();

  const {
    currency: { code },
  } = useSelector(appState);

  console.log({ type, activePlan });

  const accentColor = (bgColor) => {
    try {
      if (!bgColor) return '#2B3B82';
      const color = bgColor.replace('#', '');
      const r = parseInt(color.substring(0, 2), 16);
      const g = parseInt(color.substring(2, 4), 16);
      const b = parseInt(color.substring(4, 6), 16);
      const brightness = Math.round(((r * 299) + (g * 587) + (b * 114)) / 1000);
      return brightness > 125 ? '#000' : '#fff';
    } catch {
      return '#FFFFFF';
    }
  }

  return type === 'free' ? (
    <div
      className={`${styles.status_upgrade} ${profileStyles.status_upgrade_profile} navbar-status_upgrade_navbar`}
    >
      <p className={styles.text_upgrade}>
        Upgrade <span>Premium Account!</span>
      </p>
      <p className={`${styles.text_upgrade} ${styles.sub}`}>
        Unlock And take advantage of more features
      </p>
      <div className="flex gap-3">
        <div
          role="presentation"
          onClick={onUpgrade}
          className={styles.button_container}
        >
          <IconStar />
          <p className={styles.text_upgrade_button}>Upgrade</p>
        </div>
        <button
          onClick={async () => {
            const token = await getIdToken(auth.currentUser);
            dispatch(
              setIframePopup({
                open: true,
                url: `${paymentUrl()}/Redeem?embed=true&hide_close=true&distinctId=${currentDistinctId()}&currency=${code}&token=${token}`,
              }),
            );
          }}
          className="cursor-pointer rounded-lg bg-[#2C67F2] p-1.5 w-full text-white flex gap-2 items-center justify-center text-sm font-semibold"
          type="button"
        >
          <BiSolidCoupon /> Redeem
        </button>
      </div>
    </div>
  ) : (
    <div
      className={classnames(
        styles.plan_status,
        profileStyles.plan_status_profile,
        'rounded-md !h-full p-3 flex flex-col gap-3 text-white',
      )}
      style={{
        backgroundColor: activePlan.bg_color ? `#${activePlan.bg_color}`.replace('##', '#') : '#2B3B82',
        color: accentColor(`#${activePlan.bg_color}`.replace('##', '#')),
      }}
    >
      <div className="flex w-full justify-between items-center">
        <div>
          <p className="font-bold text-xl">
            {activePlan ? activePlan.plans_name : ''}
          </p>
          {activePlan.subscription_id
            && activePlan.transaction_status === 'Settlement' && (
              <p className="text-xs">Monthly Subscription (auto-renew)</p>
            )}
          {activePlan.subscription_id
            && activePlan.transaction_status === 'Cancel' && (
              <p className="text-xs">Monthly Subscription (canceled)</p>
            )}
          {!activePlan.subscription_id
            && activePlan.transaction_status === 'Settlement' && (
              <p className="text-xs">One-time Purchased</p>
            )}
        </div>
        <img src={activePlan.plans_thumbnail} className="h-10" alt="" />
      </div>
      <div className="flex w-full justify-center gap-3 !items-center text-sm">
        <button
          className="cursor-pointer rounded-lg bg-[#2C67F2] p-1.5 w-full text-white"
          type="button"
          onClick={() => push('/account/plan')}
        >
          Manage Plan
        </button>
        <button
          onClick={async () => {
            const token = await getIdToken(auth.currentUser);
            dispatch(
              setIframePopup({
                open: true,
                url: `${paymentUrl()}/Redeem?embed=true&hide_close=true&distinctId=${currentDistinctId()}&currency=${code}&token=${token}`,
              }),
            );
          }}
          className="cursor-pointer rounded-lg bg-[#2C67F2] p-1.5 w-full text-white flex gap-2 items-center justify-center"
          type="button"
        >
          <BiSolidCoupon /> Redeem
        </button>
      </div>
    </div>
  );
}

export default UpgradePlanCard;
