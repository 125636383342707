/* eslint-disable prefer-destructuring */
/* eslint-disable eqeqeq */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/display-name */
/* eslint-disable react/jsx-props-no-multi-spaces */
import {
  useEffect, useState, memo, forwardRef,
  useMemo,
} from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { useDispatch, useSelector } from 'react-redux';
import { FaChevronDown } from '@react-icons/all-files/fa/FaChevronDown';
import { useRouter } from 'next/router';
import {
  getAuth, getIdToken, getRedirectResult,
} from 'firebase/auth';
import Login from '../popup/login';
import { setUserDetail } from '../../store/actions/userActions';
import { setLogin } from '../../store/actions/popupActions';
import 'react-dropdown/style.css';
import instance from '../../config/axios';
import UpgradePlanCard from '../miscellaneous/upgrade_plan_card';
import Loader from '../miscellaneous/loader';
import icon from './iconUrl';
import { paymentUrl } from '../../config/getdomain';
import {
  IconCloseBlr, IconSearch,
} from '../icons/Icons';
import { analyticTracking, loginPopupAnalyticTracking } from '../controllers/analytic_tracking';
import SearchBarExplore from '../miscellaneous/searchbarExplore';
import useAuth from '../../hooks/useAuth';
import OnBoardingServices from '../../services/OnBoardingServices';
import useUserAgent from '../../hooks/useUserAgent';
import { helperState } from '../../store/reducers/helperReducer';
import { asyncGetCategories, creationState } from '../../store/reducers/creationReducer';
import classnames from '../../utils/classnames';
import ImageResizer from '../miscellaneous/image_resizer';
import onErrorProfilePicture from '../../utils/onErrorProfilePicture';
import useFetchTime from '../../hooks/useFetchTime';
import { currentDistinctId } from '../../hooks/useAnalytics';
import {
  appState, setCurrency, setIframePopup,
} from '../../store/reducers/appReducer';
import API_URL from '../../config/API_URL';
import _userAgent from '../../utils/userAgent';
import useCreations from '../../hooks/useCreations';
import FunctionCallLimiter from '../../utils/limiterFunc';
import Versioning from '../../utils/Versioning';
import { BsStars } from "react-icons/bs";
import { authState, setUserPlan } from '../../store/reducers/authReducer';
import { clearCacheByKeys } from '../../utils/cacheRoutes';

const CustomImage = forwardRef(({
  alt, width, height, src = '', ...props
}, refEl) => (
  <ImageResizer
    ref={refEl}
    type="profile"
    src={src}
    alt={alt}
    onError={onErrorProfilePicture}
    sizes={props.sizes || [width, height]}
    size="custom"
    width={width}
    height={height}
    {...props}
  />
));

CustomImage.displayName = 'CustomImage';

const coinFetchLimiter = new FunctionCallLimiter(1, 5000);
const checkRewardLimiter = new FunctionCallLimiter(1, 5000);

export default function Navbar({ mode = 'default' }) {
  const auth = getAuth();
  const router = useRouter();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [, setCategories] = useState([]);
  const [activePage, setActivePage] = useState('');
  const { userPlan: activePlan = {
    expire_date: new Date(),
  } } = useSelector(authState)

  const setActivePlan = (value) => dispatch(setUserPlan(value ? {
    ...activePlan,
    ...value,
  } : null));
  const [initialLoad, setInitialLoad] = useState(false);
  const [currentCoin, setCurrentCoin] = useState(typeof window !== 'undefined' ? localStorage.getItem('coin_cache') ? JSON.parse(localStorage.getItem('coin_cache')).coin : 0 : 0);
  const [showLoadingCoin, setShowLoadingCoin] = useState(false);
  const [toggleSearch, setToggleSearch] = useState(false);
  const [rewardFetch, setRewardFetch] = useState(false);
  const [, setText] = useState(
    router.query.search ? router.query.search : '',
  );
  const [userAgent, setUserAgent] = useState('');
  const [exploreHeight, setExploreHeight] = useState('15%');

  const showLogin = useSelector((state) => state.popup.login);
  const { exploreSearchPosition } = useSelector(helperState);
  const { currency: { code } } = useSelector(appState);
  const { categories: categoriesState, loadingCategories } = useSelector(creationState);

  const { logout, renewToken, userData } = useAuth();
  const { setLastFetchTime, mustFetch } = useFetchTime();
  const { screenWidthGreaterThan } = useUserAgent();
  const fetchCategory = async () => {
    if (categoriesState?.length === 0 && !loadingCategories) {
      dispatch(asyncGetCategories());
    }
  };

  const fetchCoin = async (uid, withoutLimiter = false) => {
    const _fetchCoint = async (clearCache = false) => {
      const authFb = getAuth();
      if (clearCache) {
        await clearCacheByKeys(`api/features/total/coin/${uid || authFb?.currentUser?.uid}`);
      }
      const { data } = await instance.get(`/api/features/total/coin/${uid || authFb?.currentUser?.uid}`)
        .catch(() => ({ data: { coin: 0 } }))
      setCurrentCoin(data.coin);
      localStorage.setItem('coin_cache', JSON.stringify(data));
      setTimeout(() => {
        setShowLoadingCoin(false);
      }, 200);
    };

    if (withoutLimiter) _fetchCoint(true);
    else coinFetchLimiter.call(_fetchCoint);
  };

  const registerReward = async (registType) => {
    if (user && Versioning.ONE_TIME_CREATION()) {
      return;
    }
    try {
      const token = await getIdToken(auth.currentUser);
      if (registType === 'first') {
        await instance.post('/api/v2/transactions/reward/', {
          action: 'register',
        }, {
          headers: { Authorization: token },
        });
      }
      if (registType === 'close') {
        await instance.put('/api/v2/transactions/reward/', {
          action: 'register',
        }, {
          headers: { Authorization: token },
        });
      }
      await fetchCoin(auth.currentUser.uid);
      localStorage.setItem('reward-popup', 'false');
      return true;
    } catch (error) {
      console.error(error);
      localStorage.setItem('reward-popup', 'false');
      return false;
    }
  };

  const showProfileDropdown = async () => {
    const docs = document.getElementById('profileDropdown');
    if (docs) docs.classList.toggle('show');
    if (docs.classList.value.includes('show')) {
      try {
        const { data } = await instance.get(
          `/api/features/active/${localStorage.getItem('UUID')}`,
        );
        setActivePlan(data.active_plan);
        localStorage.setItem('plan_cache', JSON.stringify(data.active_plan));
        setTimeout(() => {
          localStorage.removeItem('plan_cache');
        }, 1800000);
      } catch (error) {
        setActivePlan(null);
      }
    }
  };

  const openIframe = async (paymentType, data = {}) => {
    if (Versioning.ONE_TIME_CREATION() && paymentType === 'reward') {
      return;
    }

    const newToken = await renewToken();
    const param = {
      ...data,
      embed: true,
      distinctId: currentDistinctId(),
      currency: code,
      type: 'personal',
      token: newToken,
    };

    let url = '';
    switch (paymentType) {
      case 'blr':
        url = `${paymentUrl()}/new/checkout?embed=true&distinctId=${currentDistinctId()}&currency=${code}&type=miscellaneous&token=${newToken}`;
        break;
      case 'plan':
        url = `${paymentUrl()}/Plan?${new URLSearchParams(param)}`;
        break;
      case 'reward':
        url = `${paymentUrl()}/SuccessReward?embed=true&reward=${data?.invoice}&distinctId=${currentDistinctId()}&token=${newToken}`;
        break;
      case 'checkout-plan':
        url = `${paymentUrl()}/new/checkout?${new URLSearchParams(param)}`;
        break;
      default:
        break;
    }

    dispatch(setIframePopup({ open: true, url, isSticky: paymentType === 'reward' }));
  };

  const checkReward = async (uid) => {
    if (Versioning.ONE_TIME_CREATION()) {
      localStorage.setItem('reward-popup', 'false');
      localStorage.setItem('show-reward-popup', false);
      return;
    }
    setRewardFetch(true);
    try {
      if (screenWidthGreaterThan(1200)) {
        localStorage.setItem('show-reward-popup', false);
        const { role, sector } = await OnBoardingServices().getMyMetaOnBoarding();
        if (!role || !sector || role === '' || sector === '') {
          localStorage.setItem('reward-popup', 'false');
          localStorage.setItem('show-reward-popup', false);
          return;
        }
      }
      // setLoading(true);
      console.log({ API_URL });
      const { data } = await instance.get(`/api/v2/transactions/list-user-reward/${uid}`);
      const rewardObj = data.filter((item) => item.action === 'register');
      const detail = await rewardObj[0].details.filter((item) => item.label === 'register');
      if (detail.length) {
        if (!detail[0].opened) {
          localStorage.setItem('reward-popup', 'true');
          localStorage.setItem('show-reward-popup', true);
        }
        if (!detail[0].opened || localStorage.getItem('reward-popup') === 'true') {
          if (!detail[0].opened) {
            localStorage.setItem('reward-popup', 'true');
            localStorage.setItem('show-reward-popup', true);
            openIframe('reward', { invoice: detail?.[0]?.invoice });
          }
        } else {
          localStorage.setItem('reward-popup', 'false');
          localStorage.setItem('show-reward-popup', false);
        }
      } else {
        localStorage.setItem('reward-popup', 'false');
      }
    } catch (error) {
      console.error(error);
      localStorage.setItem('reward-popup', 'false');
    }
  };

  const { createProject, openBuyCreationQuota } = useCreations();

  const setSearchExplore = () => {
    if (router.pathname.includes('explore')) {
      if (exploreSearchPosition?.topFromBody) {
        const position = exploreSearchPosition?.topFromBody;
        if (position <= 0) {
          setExploreHeight('15%');
        } else {
          setExploreHeight(`${exploreSearchPosition?.topFromBody}px`);
        }
      } else {
        setExploreHeight('15%');
      }
    }
  };

  useEffect(() => {
    if (categoriesState?.length > 0) {
      setCategories(categoriesState);
    }
  }, [categoriesState]);

  useEffect(() => {
    if (router.pathname.includes('explore')) {
      setSearchExplore();
    }
  }, [router.pathname, exploreSearchPosition]);

  useEffect(() => {
    // fetchCategory();
    window.onclick = function (event) {
      if (!event.target.matches('.dropbtn')) {
        const docs = document.getElementById('profileDropdown');
        if (docs) docs.classList.remove('show');
      }
      if (!event.target.matches('.blr_button') && !event.target.matches('.blr_popup')) {
        const docs = document.getElementById('blr_coin_popup');
        const mobileNav = document.getElementById('mobile_nav');
        if (mobileNav?.className.includes('hide_nav')) {
          if (mobileNav) mobileNav?.classList.remove('hide_nav');
        }
        if (docs) docs.classList.remove('show');
      }
    };
    getRedirectResult(auth)
      .then(async (result) => {
        console.log('__result_error', result);
        // This gives you a Google Access Token. You can use it to access Google APIs.

        // The signed-in user info.
        const { user } = result;
        const registerDate = new Date(user.metadata.creationTime).getTime();
        const currentDate = new Date('2022-12-27').getTime();
        if (window.dataLayer && typeof window.dataLayer !== 'undefined') {
          const validated = localStorage.getItem('already_validated');
          if (validated !== 'true' && userData) {
            window.dataLayer.push({
              event: 'auth_validated',
              data: {
                uuid: userData.FirebaseID,
                user_avatar: userData.ProfilePictureURL,
                user_name: userData.Username,
                user_display_name: userData.Name,
              },
            });
            localStorage.setItem('already_validated', 'true');
          }
        }
        if (currentDate < registerDate) {
          await registerReward('first');
        }
        // signIn(user);
      }).catch(() => { });
    setLoading(false);
  }, [router.isReady, router.pathname]);

  useEffect(() => {
    if (typeof userData !== 'undefined' && userData !== null) {
      if (Object.keys(userData).length) {
        if (!localStorage.getItem('show-reward-popup') || localStorage.getItem('show-reward-popup') == 'false') {
          setTimeout(() => {
            if (!rewardFetch && mustFetch('userReward', { minutes: 10 })) {
              setLastFetchTime('userReward');
              // checkReward(userData.FirebaseID);
            }
          }, 2000);
        }
        // dispatch(setLogin(''));
        fetchCoin(userData.FirebaseID);
        localStorage.setItem('userData', JSON.stringify(userData));
        localStorage.setItem('UUID', userData.FirebaseID);
        const mobileNav = document.getElementById('mobile_nav');
        if (mobileNav) {
          mobileNav.classList.add('fixed_mobile_nav');
        }
        if (window.dataLayer && typeof window.dataLayer !== 'undefined') {
          const validated = localStorage.getItem('already_validated');
          if (validated !== 'true' && userData) {
            window.dataLayer.push({
              event: 'auth_validated',
              data: {
                uuid: userData.FirebaseID,
                user_avatar: userData.ProfilePictureURL,
                user_name: userData.Username,
                user_display_name: userData.Name,
              },
            });
            localStorage.setItem('already_validated', 'true');
          }
        }
      }
      if (!Object.keys(userData).length) {
        const userDetail = JSON.parse(localStorage.getItem('userData'));
        dispatch(setUserDetail(userDetail));
      }
    }
  }, [userData, userData?.ProfilePictureURL]);

  useEffect(() => {
    setInitialLoad(true);
    setLoading(false);
    if (typeof userData !== 'undefined' && userData !== null) {
      if (Object.keys(userData).length) {
        if (!currentCoin) { fetchCoin(userData.FirebaseID); }
      }
    }
    if (
      router.pathname === '/templates'
      || router.pathname === '/templates/[...categories]'
      || router.pathname === '/creation/[slug]'
    ) {
      setActivePage('templates');
      setText('');
    } else if (router.pathname === '/') {
      if (userData !== null) {
        if (Object.keys(userData).length) {
          setActivePage('');
          const feature = JSON.parse(localStorage.getItem('coin_cache'));
          if (!feature || typeof feature === 'undefined') {
            fetchCoin(userData.FirebaseID);
          }
        } else {
          setActivePage('home');
          localStorage.removeItem('from');
          localStorage.removeItem('all_creation');
          localStorage.removeItem('my-creation');
        }
      } else {
        setActivePage('home');
        localStorage.removeItem('from');
        localStorage.removeItem('all_creation');
        localStorage.removeItem('my-creation');
      }
    } else if (router.pathname === '/marketplace' || router.pathname === '/marketplace/[...categories]') {
      setActivePage('marketplace');
    } else if (router.pathname === '/explore') {
      setActivePage('explore');
    } else if (router.asPath.includes('type=project') && router.asPath.includes('mode=all') && router.asPath.includes('q=') && router.asPath.includes('noEmpty=true') && router.asPath.includes('category=') && router.asPath.includes('sort=newest')) {
      setActivePage('discover');
    } else {
      setActivePage('profile');
      setText('');
    }

    console.log({ router });
    if (window.dataLayer && typeof window.dataLayer !== 'undefined') {
      const validated = localStorage.getItem('already_validated');
      if (validated !== 'true' && userData) {
        window.dataLayer.push({
          event: 'auth_validated',
          data: {
            uuid: userData.FirebaseID,
            user_avatar: userData.ProfilePictureURL,
            user_name: userData.Username,
            user_display_name: userData.Name,
          },
        });
        localStorage.setItem('already_validated', 'true');
      }
    }
    setInitialLoad(false);
  }, [router]);

  useEffect(() => {
    if (userData !== null) {
      if (Object.keys(userData).length) {
        fetchCoin(userData.FirebaseID);
      }
    }
  }, [initialLoad]);

  useEffect(() => {
    if (router.pathname.includes('explore') && !toggleSearch) {
      document.body.style = 'overflow: auto';
    }
  }, [toggleSearch]);

  const fetchActivePlan = async (uuid) => {
    if (uuid !== undefined) {
      const planCache = JSON.parse(localStorage.getItem('plan_cache'));
      try {
        if (planCache !== null) {
          setActivePlan(planCache);
        } else {
          const { data } = await instance.get(`/api/features/active/${uuid}`);
          setActivePlan(data.active_plan);
          localStorage.setItem('plan_cache', JSON.stringify(data.active_plan));
          setTimeout(() => {
            localStorage.removeItem('plan_cache');
          }, 1800000);
        }
      } catch (error) {
        setActivePlan(null);
      }
    }
  };

  const Icon = memo(({
    name, normal, active, hover, special, routers = [],
  }) => (
    <div style={{ position: 'relative' }}>
      <Image
        width={20}
        height={20}
        src={routers.includes(router.pathname) ? active : activePage === name ? active : normal}
        alt=""
        className="navbar-png-icon-unhover"

        priority
      />
      <Image
        width={20}
        height={20}
        src={hover}
        alt=""
        className="navbar-png-icon-hover"

        priority
      />
      {special && (
        <div className="navbar-special_icon_menu">
          <Image
            width={40}
            height={40}
            src="/assets/gifs/shine.gif"
            alt=""

            priority
          />
        </div>
      )}
    </div>
  ));

  useEffect(() => {
    if (userData !== null) {
      fetchActivePlan(userData.FirebaseID);
    }
  }, [router]);

  useEffect(() => {
    if (router.pathname === '/templates') {
      dispatch(setLogin(''));
      const mobileNav = document.getElementById('mobile_nav');
      mobileNav?.classList.add('fixed_mobile_nav');
    }

    const header = document.querySelector('#container_navbar div#header');
    if (
      router.pathname !== '/marketplace/[slug]'
      || router.query.slug === 'pro'
      || router.query.slug === 'premium'
      || router.query.slug === 'free'
      || router.query.slug === 'owned'
    ) {
      header.classList.remove('hidden_navbar');
    } else header.classList.add('hidden_navbar');
  }, [router]);

  const messagelistener = async (e) => {
    if (e.data.id === 'show-upgrade-plan') {
      openIframe('checkout-plan', { group: e.data.group, duration: e.data.duration });
    }

    if (e.data.id === 'change-currency') {
      dispatch(setCurrency(e.data.value));
    }
    if (e.data.id === 'close-search') setToggleSearch(false);
    if (e.data.id === 'open-search') {
      setToggleSearch(true);
    }
    if (e.data === 'go to template') {
      registerReward('close');
      const header = document.querySelector('#container_navbar div#header');
      header.classList.add('show');
      setLoading(true);
      localStorage.setItem('reward-popup', 'false');
      const params = {};
      if (window.location.pathname.includes('marketplace') && !Number.isNaN(window.location.pathname.split('/')[2].split('-')[0])) {
        params.bundleId = window.location.pathname.split('/')[2].split('-')[0];
      }
      createProject({
        openNewTab: false,
        withParams: params,
      });
      setTimeout(() => {
        setLoading(false);
      }, 500);
    }
    if (e.data === 'go to marketplace') {
      registerReward('close');
      setLoading(true);
      localStorage.setItem('reward-popup', 'false');
      router.push('/marketplace');
    }
    if (e.data === 'show blr') {
      setShowLoadingCoin(true);
      localStorage.setItem('reward-popup', 'false');
      registerReward('close');
      fetchCoin(userData.FirebaseID);
      const popup = document.getElementById('blr_coin_popup');
      popup.classList.add('show');
    }
    if (e.data.id === 'show blr coin') {
      fetchCoin(e.data.uid);
    }
    if (e.data.id === 'list-reward') {
      setTimeout(() => {
        checkRewardLimiter.call(() => checkReward(e.data.uid));
      }, 2000);
    }
  };

  useEffect(() => {
    setUserAgent(navigator?.userAgent);
    window.addEventListener('message', messagelistener);

    return () => {
      window.removeEventListener('message', messagelistener);
    };
  }, [code]);

  const [userAdmin, setUserAdmin] = useState(false);

  const { user, checkUser } = useAuth();
  useEffect(() => {
    if (checkUser) {
      if (checkUser?.type_account) {
        setUserAdmin(true);
      } else {
        if (router.asPath === '/creator') {
          router.push('/');
        }
        setUserAdmin(false);
      }
    } else {
      if (router.asPath === '/creator') {
        router.push('/');
      }
      setUserAdmin(false);
    }
  }, [router, user, checkUser]);

  // RESOLVE DELAY USER AFTER REFRESH, TODO : Change
  const [loadCurrentUser, setLoadCurrentUser] = useState(_userAgent.isMobile.Assemblr());

  useEffect(() => {
    if (!_userAgent.isMobile.Assemblr()) {
      setLoadCurrentUser(false);
    }
    setTimeout(() => {
      setLoadCurrentUser(true);
    });
  }, [userData]);

  const hiddenAtMobile = useMemo(() => {
    const paths = ['/projects', '/markers', '/account', '/creation', '/search'];
    return paths.some((path) => router.pathname.includes(path));
  }, [router.pathname]);

  return (
    <div className={classnames("navbar-container_navbar bg-[#eff0f4]", {
      '!hidden mw:!block': hiddenAtMobile,
    })} id="container_navbar">
      <div id="header">
        <div
          className={`navbar-tablet_up ${router.pathname.includes('profile') ? 'navbar-hide' : ''
            }`}
        >
          <div
            className="navbar-navbar_container navbar-main_element"
          >
            <Link
              href={userData ? '/projects' : '/explore'}
              className={classnames('icon_logo navbar-navbar_element navbar-icon_logo', {
                '!m-0': mode === 'landing-page',
              })}
            >

              <img
                className="navbar-image_header"
                src="/assets/icons/assemblr_only_logo.svg"
                alt="Assemblr Logo"
                width={36}
                height={36}
              />
              <div className="navbar-logo_title">
                ASSEMBLR
                {' '}
                <br />
                {' '}
                <span>STUDIO</span>
              </div>

            </Link>
            {
              mode === 'landing-page' && (
                <Link
                  href={userData ? '/projects' : '/explore'}
                  className="!ml-10"
                >
                  <div className="navbar-brand">
                    Assemblr studio
                  </div>
                </Link>
              )
            }

            {(!userAgent.toLowerCase().includes('assemblr') && userData !== null)
              ? (
                <>
                  {Object.keys(userData).length ? (
                    <Link href="/projects" passHref legacyBehavior>
                      <a
                        className={classnames(
                          'navbar-navbar_element',
                          ['/projects', '/markers'].includes(router.pathname) && 'navbar-active',
                        )}
                        onClick={() => setActivePage('')}
                      >
                        <div className="navbar-menu_container">
                          <Icon
                            name=""
                            normal={icon.projects.hover}
                            active={icon.projects.active}
                            hover={icon.projects.hover}
                            routers={['/projects', '/markers']}
                          />
                          <p>Your Projects</p>
                        </div>
                      </a>
                    </Link>
                  ) : (
                    <Link
                      href="/"
                      passHref
                      className={`navbar-navbar_element ${activePage === 'explore' ? 'navbar-active' : ''
                        }`}
                      onClick={() => setActivePage('explore')}
                    >
                      <div className="navbar-menu_container">
                        <Icon
                          name="explore"
                          normal={icon.home.normal}
                          active={icon.home.active}
                          hover={icon.home.hover}
                          special
                        />
                        <p>Home</p>
                      </div>

                    </Link>
                  )}
                </>
              ) : null}

            {!userAgent.toLowerCase().includes('assemblr') && !userData && mode === 'default' && (
              <>
                {activePage === 'explore' ? (
                  <a
                    className={`navbar-navbar_element ${activePage === 'explore' ? 'navbar-active' : ''
                      }`}
                    onClick={() => setActivePage('explore')}
                  >
                    <div className="navbar-menu_container">
                      <Icon
                        name="explore"
                        normal={icon.home.hover}
                        active={icon.home.active}
                        hover={icon.home.hover}
                        special
                      />
                      <p>
                        {/* Explore
                        {' '}
                        <span>NEW</span> */}
                        Start Here
                      </p>
                    </div>
                  </a>
                ) : (
                  (
                    <Link
                      href="/explore"
                      passHref
                      className={`navbar-navbar_element ${activePage === 'explore' ? 'navbar-active' : ''
                        }`}
                      onClick={() => setActivePage('explore')}
                    >

                      <div className="navbar-menu_container">
                        <Icon
                          name="explore"
                          normal={icon.home.hover}
                          active={icon.home.active}
                          hover={icon.home.hover}
                          special
                        />
                        <p>
                          {/* Explore
                          {' '}
                          <span>NEW</span> */}
                          Start Here
                        </p>
                      </div>

                    </Link>
                  )
                )}
              </>
            )}

            {/* {!userAgent.toLowerCase().includes('assemblr') && (
              <div className="navbar-navbar_element dropdown_template">
                <div
                  className={`navbar-nav_element_container navbar-template_nav ${activePage === 'templates' ? 'navbar-active' : ''
                  } dropbtn_template`}
                >
                  <Link
                    href="/templates"
                    passHref
                    className={`navbar-container_element ${activePage === 'templates' ? 'navbar-active' : ''
                    }`}
                    onClick={() => setActivePage('')}
                  >

                    <div className="navbar-menu_container">
                      <Icon
                        name="templates"
                        normal={icon.templates.hover}
                        active={icon.templates.active}
                        hover={icon.templates.hover}
                      />
                      <p className="dropbtn_template_text">Templates</p>
                    </div>

                  </Link>
                </div>
                <div className="navbar-template dropcontent_template">
                  {categories.map((category, i) => (
                    (
                      <Link
                        key={i}
                        href={`/templates/${category.slug}`}
                        passHref
                        shallow={false}
                        as={undefined}
                        onClick={() => {
                          setLoading(true);
                          setActivePage('templates');

                          setTimeout(() => {
                            setLoading(false);
                          }, 4000);
                        }}
                      >

                        <div
                          className="dropcontent_template_text"
                          onMouseEnter={() => toggleSubDropdown(category.name)}
                          onMouseLeave={() => toggleSubDropdown('')}
                        >
                          <p className="disable_select">{category.name}</p>
                        </div>

                      </Link>
                    )
                  ))}
                </div>
              </div>
            )} */}

            {(user && !Versioning.ONE_TIME_CREATION()) && (!userAgent.toLowerCase().includes('assemblr') && mode === 'default' ? (
              <>
                {activePage === 'marketplace' ? (
                  // <Link href={"/marketplace"} passHref>
                  <a
                    className={`navbar-navbar_element ${activePage === 'marketplace' ? 'navbar-active' : ''
                      }`}
                    onClick={() => setActivePage('marketplace')}
                  >
                    <div className="navbar-menu_container">
                      <Icon
                        name="marketplace"
                        normal={icon.marketplace.hover}
                        active={icon.marketplace.active}
                        hover={icon.marketplace.hover}
                      />
                      <p>
                        Marketplace
                      </p>
                    </div>
                  </a>
                ) : (
                  // </Link>
                  (
                    <Link
                      href="/marketplace"
                      passHref
                      className={`navbar-navbar_element ${activePage === 'marketplace' ? 'navbar-active' : ''
                        }`}
                      onClick={() => setActivePage('marketplace')}
                    >

                      <div className="navbar-menu_container">
                        <Icon
                          name="marketplace"
                          normal={icon.marketplace.hover}
                          active={icon.marketplace.active}
                          hover={icon.marketplace.hover}
                        />
                        <p>
                          Marketplace
                        </p>
                      </div>

                    </Link>
                  )
                )}
              </>
            ) : null)}

            {(user && !Versioning.ONE_TIME_CREATION()) && (!userAgent.toLowerCase().includes('assemblr') && mode === 'default' ? (
              <>
                {activePage === 'discover' ? (
                  // <Link href={"/marketplace"} passHref>
                  <a
                    className={`navbar-navbar_element ${activePage === 'discover' ? 'navbar-active' : ''
                      }`}
                    onClick={() => setActivePage('discover')}
                  >
                    <div className="navbar-menu_container">
                      <Icon
                        name="discover"
                        normal={icon.explore.hover}
                        active={icon.explore.active}
                        hover={icon.explore.hover}
                      />
                      <p>
                        Discover
                      </p>
                    </div>
                  </a>
                ) : (
                  // </Link>
                  (
                    <Link
                      href="/search?type=project&mode=all&sort=newest&from=tag"
                      shallow
                      passHref
                      className={`navbar-navbar_element ${activePage === 'discover' ? 'navbar-active' : ''
                        }`}
                      onClick={() => setActivePage('discover')}
                    >

                      <div className="navbar-menu_container">
                        <Icon
                          name="discover"
                          normal={icon.explore.hover}
                          active={icon.explore.active}
                          hover={icon.explore.hover}
                        />
                        <p>
                          Discover
                        </p>
                      </div>

                    </Link>
                  )
                )}
              </>
            ) : null)}
          </div>
          <div
            className="navbar-navbar_container navbar-side_element"
          >
            {(user && !Versioning.ONE_TIME_CREATION()) && (mode === 'default' && (
              <div
                className="navbar-searchToggle"
                onClick={() => {
                  router.push(router.pathname === '/marketplace' ? '/search?mode=marketplace&type=inMarketplace' : '/search');
                }}
              >
                <IconSearch />
              </div>
            ))}

            {(user && Versioning.ONE_TIME_CREATION())
              && screenWidthGreaterThan(767)
              && (!activePlan?.expire_date
                || (activePlan?.expire_date
                  && new Date(activePlan?.expire_date) <= new Date()))
              && (mode === 'default'
                && (
                  <button
                    className="flex items-center justify-center py-1 px-2 gap-1 bg-[#F6A623] text-white rounded-lg fill-white font-bold hover:bg-[#f69e23]"
                    onClick={() => {
                      openBuyCreationQuota();
                    }}
                  >
                    <BsStars className='-rotate-90' />
                    Upgrade
                  </button>
                ))}

            {toggleSearch ? (
              <div
                id="searchWrapper"
                className={`navbar-searchWrapper ${router.pathname.includes('explore') ? 'navbar-explore' : ''}`}
                onClick={(evt) => {
                  const el = evt.target.id;
                  if (el === 'searchWrapper' || el === 'searchWrapper2') { setToggleSearch(false); }
                }}
              >
                <div style={{ top: exploreHeight }} id="searchWrapper2" className="navbar-searchContainer">
                  <SearchBarExplore idElement="navbar" withSuggest toggleSearch />
                </div>
              </div>
            ) : null}
            {userData !== null && Object.keys(userData).length ? (
              <>
                {(user && !Versioning.ONE_TIME_CREATION()) && (
                  <div
                    onClick={() => {
                      const popup = document.getElementById('blr_coin_popup');
                      setShowLoadingCoin(true);
                      if (!popup.classList.contains('show')) {
                        fetchCoin(userData.FirebaseID, true);
                      }
                      popup.classList.toggle('show');
                    }}
                    className="navbar-blr_coin_container"
                  >
                    <button
                      type="button"
                      className="navbar-blr_coin_button blr_button"
                    >
                      <img
                        className="navbar-tablet blr_button"
                        src="/assets/icons/blr_coin.svg"
                        alt="Blr Coin"
                        width={20}
                        height={20}
                      />
                      <p className="blr_button">
                        {currentCoin.toLocaleString('en-US')}
                      </p>
                    </button>
                    <section
                      onClick={() => {
                        const mobileNav = document.getElementById('mobile_nav');
                        if (mobileNav?.className.includes('hide_nav')) {
                          mobileNav.classList.remove('hide_nav');
                        }
                      }}
                      id="blr_coin_popup"
                      className="navbar-blr_coin_popup blr_popup"
                    >
                      <div className="navbar-rectangle" />
                      <header>
                        <p>Your BLR Coin</p>
                        <IconCloseBlr
                          onClick={() => {
                            const mobileNav = document.getElementById('mobile_nav');
                            if (mobileNav?.className.includes('hide_nav')) {
                              if (mobileNav) { mobileNav?.classList.remove('hide_nav'); }
                            }
                          }}
                        />
                      </header>
                      {showLoadingCoin ? (
                        <div className="container_spinner">
                          <div className="spinner">
                            <div className="spinner-item" />
                            <div className="spinner-item" />
                          </div>
                        </div>
                      ) : (
                        <section>
                          <div className="navbar-balance">
                            <div className="navbar-current_balance">
                              <p>Current Balance:</p>
                              <p className="navbar-gold">
                                <span>
                                  {currentCoin.toLocaleString('en-US')}
                                </span>
                                {' '}
                                BLR Coin
                              </p>
                            </div>
                            <img
                              className="navbar-tablet"
                              src="/assets/icons/blr_coin.svg"
                              alt="Blr Coin"
                              width={20}
                              height={20}
                            />
                          </div>
                          <section>
                            <button
                              type="button"
                              onClick={() => {
                                analyticTracking(
                                  userData.FirebaseID,
                                  'blr_widget_header',
                                  'topup_blr',
                                );
                                openIframe('blr');
                                setLoading(true);
                                // setTimeout(() => {
                                const mobileNav = document.getElementById('mobile_nav');
                                if (
                                  mobileNav?.className.includes(
                                    'hide_nav',
                                  ) === false
                                ) {
                                  if (mobileNav) { mobileNav.classList.add('hide_nav'); }
                                }
                                // document.getElementById("mobile_nav").classList.add("hide_nav")
                                // }, 100)
                                setTimeout(() => {
                                  setLoading(false);
                                }, 400);
                              }}
                            >
                              <p>Top Up</p>
                            </button>
                            <button
                              onClick={() => {
                                if (_userAgent.isMobile.Assemblr()) {
                                  window.location.href = 'assemblr://openurl?url=https://bit.ly/3v3NCE4';
                                  console.log({ url: 'assemblr://openurl?url=https://bit.ly/3v3NCE4' });
                                } else {
                                  window.open('https://bit.ly/3v3NCE4', '_blank');
                                }
                              }}
                              type="button"
                              alt="Learn More"
                            >
                              <p>Learn More</p>
                            </button>
                          </section>
                        </section>
                      )}
                      {/* <button className={styles["transaction"]}>
                            <IconTransaction />
                            <p>Transaction History</p>
                            <IconChevronRight />
                        </button> */}
                    </section>
                  </div>
                )}
                <div className="navbar-dropdown_container !hidden unhidden mw:!block">
                  <div
                    onClick={() => showProfileDropdown()}
                    className="navbar-profile_section dropbtn"
                  >
                    <div className="disable_select">
                      <CustomImage
                        className="navbar-profile_thumbnail dropbtn disable_select"
                        draggable={false}
                        src={userData.ProfilePictureURL}
                        alt="Profile Thumbnail"
                        width={35}
                        height={35}
                        sizes={[60, 60]}
                      />
                    </div>
                    <FaChevronDown
                      className="navbar-icon dropbtn"
                    />
                  </div>
                  <div
                    onClick={async () => {
                      if (userData === null) {
                        loginPopupAnalyticTracking('header_btn');
                        dispatch(setLogin('login'));
                        return;
                      }
                      if (router.asPath.includes(userData.Username) === false) {
                        router.push((Versioning.ONE_TIME_CREATION()) ? '/account/setting' : '/account/plan');
                      }
                      setLoading(false);
                      const { data } = await instance.get(
                        `/api/features/active/${localStorage.getItem(
                          'UUID',
                        )}`,
                      );
                      localStorage.setItem(
                        'plan_cache',
                        JSON.stringify(data.active_plan),
                      );
                    }}
                    className="navbar-profile_section_mobile dropbtn"
                  >
                    <div className="disable_select">
                      <CustomImage
                        className="navbar-profile_thumbnail dropbtn disable_select"
                        draggable={false}
                        src={userData.ProfilePictureURL}
                        alt="Profile Thumbnail"
                        sizes={[60, 60]}
                        width={35}
                        height={35}
                      />
                    </div>
                  </div>

                  <div
                    id="profileDropdown"
                    className="navbar-dropdown"
                  >
                    <div className="navbar-profile_element border-b-[1px] border-solid border-slate-300">
                      <div className="navbar-user_info">
                        <CustomImage
                          className="navbar-profile_thumbnail dropbtn"
                          src={userData.ProfilePictureURL}
                          alt="Profile Thumbnail"
                          width={60}
                          height={60}
                        />
                        <div className="navbar-user_desc dropbtn">
                          <p className="navbar-display dropbtn">
                            {userData.Name}
                          </p>
                          <p
                            className="navbar-username dropbtn"
                          >
                            {`@${userData.Username}`}

                          </p>
                        </div>
                      </div>
                      {(user && !Versioning.ONE_TIME_CREATION()) && (
                        <UpgradePlanCard
                          type={(!activePlan || typeof activePlan.plans_name === 'undefined') ? 'free' : 'member'}
                          activePlan={activePlan}
                          onUpgrade={() => {
                            if (!activePlan || typeof activePlan.plans_name === 'undefined') {
                              analyticTracking(
                                userData.FirebaseID,
                                'profile_menu',
                                'upgrade_plan',
                              );
                            }

                            openIframe('plan');
                          }}
                        />
                      )}
                    </div>
                    <div
                      className="navbar-dropdown_element"
                      onClick={() => {
                        const username = JSON.parse(
                          localStorage.getItem('userData'),
                        ).Username;
                        router.push(`/profile/${username.replace(' ', '%20')}`, null, {
                          shallow: true,
                        });
                        localStorage.removeItem('upgrade_detail_cache');
                      }}
                    >
                      <p>
                        View Public Profile
                      </p>
                    </div>

                    {userAdmin ? (
                      <div
                        className="navbar-dropdown_element"
                        onClick={() => {
                          router.push('/creator');
                        }}
                      >
                        <p>
                          Creator Studio
                        </p>
                      </div>
                    ) : null}

                    <div
                      className="navbar-dropdown_element"
                      onClick={() => {
                        router.push('/account/setting');
                      }}
                    >
                      <p>
                        Account Settings
                      </p>
                    </div>

                    <div
                      className="navbar-dropdown_element"
                      onClick={logout}
                    >
                      <p>
                        Sign Out
                      </p>
                    </div>
                  </div>
                </div>
              </>
            ) : (loadCurrentUser
              && (
                <div
                  onClick={() => {
                    loginPopupAnalyticTracking('header_btn');
                    dispatch(setLogin('login'));
                    const mobileNav = document.getElementById('mobile_nav');
                    if (mobileNav) mobileNav.classList.remove('fixed_mobile_nav');
                  }}
                  className="navbar-button navbar-register"
                >
                  <p className="navbar-text_login_register">
                    Login / Register
                  </p>
                </div>
              )
            )}
          </div>
        </div>
      </div>
      {showLogin ? <Login /> : null}
      <Loader show={loading} />
    </div>
  );
}
